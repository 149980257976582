.sliderWraper {
    overflow: visible;
    width: 100%;
    /* z-index: 2; */
}

.diamond {
    translate: 0% -28%;
    margin: 0px;
    padding: 0px;
}

.tileWraper>div:nth-of-type(1) {
    translate: 0% -22%;
}

.sliderBackground {
    background: url(../../assets/SliderBackground.png);
    background-size: 100% 82%;
    background-position: center;
    background-repeat: no-repeat;
    height: 165px;
    padding: 20px 30px;
    width: 100%;
    max-width: 700px;
    overflow: visible;
    z-index: 1;
}

.imageSlider {
    scroll-behavior: smooth;
}

.flip-image {
    transform: scaleX(-1);
}

.zoom {
    position: relative;
    clip-path: circle(10px at 50% 50%) !important;
    /* width: 60px !important;
    height: 60px !important; */
    scale: 150%;
}