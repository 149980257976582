.dropdown-item {
    width: 200px;
    padding: 10px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    height: 100%;
    cursor: pointer;
    pointer-events: auto;
}

.dropdown-img {
    width: 60%;
    pointer-events: none;
}

.dropDownItemBorder {
    pointer-events: none;
    z-index: 2;
    width: 110%;
    height: 110;
}

.border {
    border: 2px solid rgb(59, 59, 59);
    border-radius: 10px;
}

.border-selected {
    border: none !important;
    background-image: url("../../assets/BlueRectangle.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 110% 110%;
    /* padding: 5%; */
}