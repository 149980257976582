.equipment-container {
  position: relative;
  z-index: 1;
  top: 130px;
}

.modal-container {
  background-image: url(../../assets/modalFrame.png);
  background-repeat: round;
  background-size: cover;
  overflow: scroll;
  margin: 0% 20% 12%;
  width: 73%;
  max-width: 1080px;
  min-width: 768px;
  height: 680px;
  justify-self: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.selectedItemDesc {
  font-family: "CarterOne";
  text-align: left;
  margin-left: 14%;
  margin-top: -13%;
  color: #ffffff;
  font-size: 25px;
  text-shadow: 1.14509px 1.29px 1.14509px rgba(0, 0, 0, 0.38);
}

.itemDetail {
  font-family: "CarterOne";
  color: #c3cacc;
  text-align: left;
  border: 1px solid #c3cacc;
  border-radius: 15px;
  padding: 0% 10%;
  margin-right: 25%;
}

.ratingDiv {
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.ratingDiv p {
  margin-left: 5%;
}

.itemDetailDiv2 {
  display: flex;
  font-family: "CarterOne";
  justify-content: space-between;
  margin-top: 4%;
  margin-right: 25%;
}

.itemDetailSubDiv1 {
  padding: 0% 20% 0% 3%;
  border: 1px solid #c3cacc;
  border-radius: 15px;
  text-align: left;
}

.itemDetailSubDiv2 {
  padding: 0% 20% 0% 3%;
  border: 1px solid #c3cacc;
  border-radius: 15px;
  text-align: left;
}

.itemClass p {
  color: #c3cacc;
  font-size: large;
}

.itemClassName {
  display: flex;
  align-items: center;
  height: 35px;
}

.itemClassName img {
  height: 20px;
  margin-right: 2%;
}

.itemClassName p {
  color: #ffffff;
  font-size: larger;
}

.ownerAddress {
  font-family: "CarterOne";
  color: #c3cacc;
  text-align: left;
  border: 1px solid #c3cacc;
  border-radius: 15px;
  padding: 0% 10%;
  margin-right: 25%;
  margin-top: 4%;
}

.metaMaskDetail {
  display: flex;
  align-items: center;
}

.close {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.equipButton {
  background-color: transparent;
  border: none;
  margin-top: 2%;
  cursor: pointer;
  margin-right: 23%;
  font-family: "CarterOne";
  background: url(../../assets/MintButton2.png);
  background-size: 100% 100%;
  height: 62px;
  width: 270px;
  color: #c3cacc;
}

.itemImg {
  background: url(../../assets/pngwing.png);
  background-repeat: round;
  /* background-size: 70% 90%; */
  /* background-position-x: 120px;
  background-position-y: 0px; */
}

.itemImg img {
  height: auto;
  width: 100%;
  margin-bottom: 20%;
  margin-left: 25%;
  border-radius: 25px;
}

/* .item1 {
  float: left;
  width: calc(50% - 10px);
  height: 100%;
  margin-top: 11%;
} */

.transferOwner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transferButton {
  background: url(../../assets/transferButton.png) no-repeat;
  background-size: 100% 100%;
  background-position: center;
  border: none;
  cursor: pointer;
  height: 30px;
  width: 30px;
}

.levelUp {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.levelUpButton {
  background: url(../../assets/levelUpButton.png) no-repeat;
  background-size: 100% 100%;
  background-position: center;
  border: none;
  cursor: pointer;
  height: 30px;
  width: 30px;
}

/* .item2 {
  float: right;
  width: calc(50% - 10px);
  height: 100%;
} */

@media (max-width: 1280px) {
  .modalImg {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: auto;
    background-size: cover;
  }

  .closeButton {
    margin-left: auto;
  }

  .itemImg {
    background-position-x: 75px;
    background-position-y: 0px;
  }

  .item2 {
    margin-left: 30%;
    margin-bottom: 20%;
    width: 100%;
  }
}

.EquippedBySome {
  font-family: "CarterOne";
  margin-right: 25%;
  font-weight: 100;
  color: #c3cacc;
}