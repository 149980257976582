/* Show the modal when it is clicked */
.modal-open {
    display: block;
}

.modal-close {
    display: null;
}

.modal .headerImage {
    background: url(../../assets/rectangleHeader.png) no-repeat;
}

.modal-background {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 4;
    top: 0%;
    background-color: rgba(0, 0, 0, 0.38);
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.modal-body-m {
    /* max-width: 1080px; */
    /* height: fit-content !important; */
    width: 50%;
    min-width: 300px;
    padding: 20px;
    background-image: url(../../assets/new.png);
    background-color: rgb(0, 0, 0);
    background-repeat: round;
    background-size: cover;
    max-height: 100%;
    height: fit-content;

    /* overflow-y: scroll; */
    /* margin-top: -35px; */
    /* overflow: hidden; */
}

.model-content-m {
    overflow: hidden;
    overflow-y: auto;
    height: fit-content;
}

.model-content-m>div {
    height: fit-content;
}

.mint-button {
    height: 150px;
    width: auto;
    margin-top: 5px;
}

.mintButton {
    background: url(../../assets/MintButton2.png) no-repeat;
    background-size: cover;
    /* background-position: 31% 100%; */
    width: 17%;
    min-width: 200px;
    height: 50px;
    color: #ffffff;
    font-family: "CarterOne";
    font-size: larger;
    -webkit-text-stroke: 0.5px black;
    text-shadow: 0.723634px 0.815208px 0.723634px rgba(0, 0, 0, 0.38);
    cursor: pointer;
    border: none;
    /* margin-top: 14%; */
    /* margin-right: 9%; */
}

.mintButton:hover {
    text-shadow: drop-shadow(5px 5px 10px rgb(255, 255, 255));
}

.close {
    color: #aaa;
    font-size: 40px;
    font-weight: bold;
    cursor: pointer;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
}

.section-container {
    border: 2px solid darkgrey;
    border-radius: 20px;
    padding: 10px;
}

.item-image {
    background-image: url(../../assets/item-container-bg.png);
    background-repeat: round;
    background-size: cover;
    height: min-content;
    /* max-height: 400px;
    min-height: 200px; */
}

.adress-container {
    width: 50%;
    min-width: 100%;
}