.dropdownDiv {
    padding: 25px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    overflow-y: auto;
}

.dropdown-text {
    color: #c3cacc;
    font-size: larger;
}

.title-section {
    background: url(../../../assets/DropDownBack.png) round;
    background-position: center;
    cursor: pointer;

}

.dropbtn {
    cursor: pointer;
    height: fit-content;
}

.dropbtn_invert {
    cursor: pointer;
    height: fit-content;
    transform: scaleY(-1);
}

.dropdown-content {
    flex: content;
    flex: 0 0 auto;
    flex-flow: row wrap;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    cursor: pointer;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    background-color: #ddd;
}