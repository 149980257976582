.FAQPage {
    background: url(../../assets/InventoryBackground.png) no-repeat;
    position: fixed;
    background-position: center;
    background-size: cover;
    height: 100%;
    width: 100%;
}

.faqMain {
    margin-top: 80px;
}

.questions {
    overflow-y: scroll;
    max-height: 70vh;
}

.question {
    border: 2px solid darkgray;
    border-radius: 15px;
}