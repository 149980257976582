.containerX {
  position: fixed;
  top: 80px;
  right: 0;
  /* margin-top: 81px; */
  width: fit-content;
  height: 100%;
  z-index: 11;
  background: url("../../../assets/Vector.png"), #070a0c;
  box-shadow: -10px 43px 70px rgba(236, 118, 10, 0.34);
  /* padding: 32px 32px 42px 32px; */
  padding: 32px;
  background-color: rgba(0, 0, 0, 0.3);
  overflow-y: auto;
}

.container-top {
  height: 80%;
}

.buttonGroup {
  position: relative;
  /* left: 15%; */
  margin-top: 20px;
  top: 10%;
  display: flex;
  justify-content: space-between;
  height: auto;
  width: 100%;
  /* gap: 10%; */
}

.filterModalButton1 {
  padding: 12px 50px;
  cursor: pointer;
  font-family: "CarterOne";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  align-items: center;
  letter-spacing: 0.04em;
  color: #ffffff;
  width: 50%;
  background: linear-gradient(90deg,
      #073033 2.84%,
      #0b5b4d 48.9%,
      #073033 96.92%);
  border-radius: 8px;
  -webkit-text-stroke: 0.1px black;
  text-shadow: 1px 1px 2px #000000;
}

.selected-option {
  color: #ffffff;
}

.filterModalButton2 {
  font-family: inherit;
  background-color: transparent;
  font-size: large;
  border: none;
  cursor: pointer;
  font-family: "CarterOne";
  height: auto;
  width: 100%;
  color: #d3d3d3;
}

.filterModalButton2:hover {
  color: #ffffff;
}

.filterModalText {
  height: 17px;
  /* Title/Secondary 16 */
  font-family: "Carter One";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  /* line-height: 100%; */
  /* or 16px */
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  color: gray;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.filterTop {
  display: flex;
  justify-content: space-between;
}

.hr {
  border: 1px solid;
  border-image-slice: 0.1;
  border-image-source: linear-gradient(to bottom,
      rgba(14, 15, 16, 1) 0%,
      rgba(140, 131, 131, 0.9) 100%);
  text-align: center;
  width: 324px;
  height: 0px;
  left: 0px;
  top: 456px;
  background: url(../../../assets/HrLine.png) no-repeat;
}

.upperCase {
  text-transform: uppercase;
}

.myfont {
  font-family: "CarterOne";
  color: gray;
}

.innerDiv {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
}

.filterDiv {
  display: flex;
}

.closeIcon:hover {
  cursor: pointer;
  filter: drop-shadow(4px 4px 8px #ec760a);
}

input[type="radio"] {
  appearance: none;
  margin-right: 5px;
  padding: 10px;
  border-radius: 50%;
  border: 1px solid #75797d;
  background: rgba(117, 121, 125, 0.3);
  box-shadow: inset 0px 1.23077px 0px rgba(255, 255, 255, 0.25);
}

/* .checked-option {
  background: #0f8cff;
  box-shadow: inset 0px 1.23077px 0px rgba(255, 255, 255, 0.55);
  border: 1px solid #ffffff; */
/* } */
.text-white {
  color: white;
}

input[type="radio"]:checked {
  background: #0f8cff;
  box-shadow: inset 0px 1.23077px 0px rgba(255, 255, 255, 0.55);
  border: 1px solid #ffffff;
  color: white;
}

@media screen and (max-width: 425px) {
  .containerX {
    width: auto;
    /* height: 100%; */
    /* margin-top: unset; */
    top: 0%;
    padding: 9px 16px 8px 25px;
  }

  .filterModalText {
    width: fit-content;
  }

  .buttonGroup {
    margin-top: 25%;
  }
}

@media screen and (max-width: 380px) {
  .containerX {
    padding: 2px 0px 0px 15px;
  }

  .buttonGroup {
    margin-top: 9px;
  }
}