.swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    /* background-color: red; */
}

.popUpQuest {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 6;
    pointer-events: none;
    background-color: transparent;
}

.popUpBg {
    height: 50vh;
    background-image: url(../../assets/new.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    pointer-events: all;
}

/* .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 300px;
}

.swiper-slide img {
    display: block;
    width: 100%;
} */