.XPLOGO {
  position: absolute;
  top: -23px;
  left: 0;
  width: 20vh;
}
.navLogo {
  position: relative;
  width: 220px;
  margin-left: 0%;
  margin-top: 2%;
  height: auto;
}
.xpnetssection {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.hrLoginScreen {
  display: none;
  border: 1px solid;
  border-image-slice: 0.1;
  border-image-source: linear-gradient(
    to bottom,
    rgb(255, 255, 255) 0%,
    rgb(255, 255, 255) 100%
  );
  text-align: center;
  width: 100%;
  height: auto;
  background: url(../../assets/HrLine.png) no-repeat;
  background-position: center;
  margin: unset;
  opacity: unset;
}
.nav_buttons {
  height: 80px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 2px 10px;
  gap: 10px;
}
.navlink {
  font-size: 16px;
  display: flex;
  align-items: center;
  text-decoration: none;
  text-align: center;
  height: 45px;
  width: 119px;
  letter-spacing: 0.04em;
  color: #c4cacc;
  opacity: 0.6;
  text-shadow: 1.14509px 1.29px 1.14509px rgba(0, 0, 0, 0.38);
  justify-content: space-around;
  background-image: url(../../assets/FrameInactive.svg);
}
.navlink:hover {
  color: #fff;
}
.navlink-active {
  background-image: url(../../assets/FrameActive.svg);
  opacity: 1;
}
.NavBar {
  background: rgba(18, 16, 17, 0.5);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: row;
  align-content: center;
  /* margin-bottom: 55px; */
  position: relative;
  z-index: 10;
}
.metasection {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.navigationbar {
  list-style: none;
  display: flex;
  gap: 26px;
  top: 0;
  position: static;
  text-decoration: none;
  font-family: CarterOne;
  margin-left: 5%;
  margin-top: 1%;
}

/* .connectedaddress {
  position: absolute;
  right: 100px;
  top: 20px;
  color: rgba(211, 203, 201, 1);
} */
@media (max-width: 1200px) {
  .navigationbar {
    margin-left: unset;
    gap: 10px;
  }
}
@media (max-width: 960px) {
  .navigationbar {
    display: none;
  }
  .xpnetssectiondrop-nav {
    display: none;
  }
}
@media (max-width: 1520px) {
  .metasection {
    display: none;
  }
  .xpnetssection {
    display: none;
  }
}
@media (max-width: 1440px) {
 
  .hrLoginScreen {
    display: block;
  }
  .metasectiondrop {
    display: flex !important;
    flex-direction: row;
    margin-bottom: 10px;
    gap: 10px;
    align-items: center;
  }
  .xpnetssectiondrop {
    display: flex !important;
    flex-direction: row;
    gap: 10px;
    padding-right: 80px;
    align-items: center;
    margin-bottom: 10px;
  }
}
@media (max-width: 1060px) {
  .navigationbar {
    gap: 0px;
  }
  .hrLoginScreen {
    display: block;
  }
  .xpnetssectiondrop-nav {
    display: flex !important;
    flex-direction: row;
    gap: 10px;
    padding-right: 80px;
    align-items: center;
    margin-bottom: 10px;
  }
}
.li {
  list-style: none;
}
/* .XPNETS {
  position: absolute;
  top: 20px;
  right: 310px;
  color: rgba(211, 203, 201, 1);
} */
.xpnetmeta {
  display: flex;
  flex-direction: row;
  justify-content: end;
  width: 100%;
  gap: 30px;
}
.mainnav {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  z-index: 10;
}
/* .xpnetimage {
  position: absolute;
  top: 15px;
  right: 460px;
  margin-right: 10px;
} */

/* .smallmetaimage {
  position: absolute;
  top: 15px;
  right: 230px;
} */
.menuheader {
  display: flex;
  align-items: center;
  margin-right: 25px;
}

.disconnectButton {
  font-size: 16px;
  display: flex;
  margin-top: 10px;
  align-items: center;
  text-align: center;
  height: 45px;
  width: 119px;
  letter-spacing: 0.04em;
  color: white;
  opacity: 0.7;
  cursor: pointer;
  margin-right: 25px;
  text-shadow: 1.14509px 1.29px 1.14509px rgba(0, 0, 0, 0.38);
  justify-content: space-around;
  background-image: url(../../assets/FrameInactive.svg);
  margin-bottom: 10px;
  position: relative;
  font-family: "CarterOne";
}

.listforheader {
  list-style: none;
}

.headerbuttonParent {
  background-image: url(../../assets/headerBack.png);
}

.dropdown-menu-m {
  min-height: 5vh;
  text-decoration: none;
  list-style: none;
  mix-blend-mode: normal;
  text-align-last: end;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-top: 10px;
  position: relative;
  z-index: 10;
}
.dropdownitems {
  display: flex;
  align-items: start;
  flex-direction: column;
  margin-right: 10px;
  border-radius: 10px;
  background: rgba(19, 17, 18, 0.9);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
@media screen and (max-width: 425px) {
  .hrLoginScreen {
    display: block;
  }
}
