.timer {
    background-image: url(../../../../assets/quests/fire-ring.png);
    background-repeat: round;
    background-size: cover;
    width: 50%;
    height: 100%;
}

.timer * {
    color: white;
}