.searchBar {
  background-position: 100% 100%;
  background-size: contain;
  height: 500px;
  width: 100%;
  z-index: 9;
  margin-top: 10%;
}

.searchBarBackground {
  background-position: center;
  background-size: 100% 100%;
  height: 119%;
  position: relative;
  left: 5%;
  width: 115%;
  top: -3%;
}

.inputDiv {
  border: none;
  height: 35px;
  background: url(../../../assets/searchBarBackground.png) no-repeat;
  background-position: center;
  background-size: 100% 100%;
  font-family: "CarterOne";
  width: 350px;
  font-size: medium;
  color: rgb(243, 243, 243);
  padding: 10px 30px 10px 10px;
}

.focusedInput {
  border: 4px solid #0088ff !important;
  border-radius: 5%;
  padding: 0px;
}

.cross-icon {
  background: url(../../../assets/closeIcon.svg) no-repeat;
  background-position: 100% 100%;
  height: 20px;
  width: 20px;
  margin-left: -30px;
  z-index: 20;
  cursor: pointer;
}

.cross-icon:hover {
  filter: drop-shadow(5px 5px 10px #ec760a);
  color: #ffffff;
}

input[type="text"],
select {
  letter-spacing: 1px;
  caret-color: #0088ff;
  font-size: 15px;
}

.cancelButtonDiv button {
  background: none;
  border: none;
  font-family: "CarterOne";
  cursor: pointer;
  color: #c3cacc;
  font-size: medium;
}

.cancelButton {}

.cancelButton:hover {
  color: #ffffff;
  filter: drop-shadow(2px 2px 4px #ec760a);
}

.searchResults .noResult {
  text-align: center;
}

.noResult img {
  height: 250px;
  width: auto;
}

.backButton {
  background: url(../../../assets/MintButton2.png) no-repeat;
  background-size: 100% 100%;
  background-position: center;
  color: #ffffff;
  font-family: "CarterOne";
  font-size: large;
  border: none;
  margin-top: 10%;
  width: 125px;
  -webkit-text-stroke: 0.5px black;
  text-shadow: 0.723634px 0.815208px 0.723634px rgba(0, 0, 0, 0.38);
  cursor: pointer;
  height: 40px;
}


.characterimg-search {
  width: 60%;
  height: auto;
  padding: 0px;
  background-position: center;
  background-repeat: round;
  cursor: pointer;
}

.resultHeading {
  text-align: center;
}

.resultHeading p {
  color: #c3cacc;
  font-family: "CarterOne";
}

.searchResults {
  display: flex;
  flex: content;
  flex: 0 0 auto;
  flex-flow: row wrap;
}

@media screen and (max-width: 600px) {
  .inputDiv {
    width: 250px;
  }
}