.inventoryPage {
  background: url(../../assets/InventoryBackground.png) no-repeat;
  position: fixed;
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
}

.fliterWraper {
  top: 0px;
}

.inventoryPageSub {
  position: relative;
  top: 15%;
}

.RectangleHeaderContainer {
  background: url(../../assets/SimpleFrame.png) no-repeat;
}

.RectangleHeader2 {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  text-align: center;
}

.RectangleHeaderText2 {
  /* position: relative; */
  font-style: normal;
  /* bottom: 45px; */
  font-weight: 400;
  font-size: 20px;
  z-index: 1;
  letter-spacing: 0.02em;
  color: #c4cacc;
  text-shadow: 1.14509px 2.29018px 1.14509px rgb(0 0 0 / 46%);
}

.bar {
  z-index: 1;
}

.bar-text {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #c4cacc;
  text-shadow: 1.14509px 2.29018px 1.14509px rgb(0 0 0 / 46%);
}

.bar-content {
  margin-top: -45px;
}

.searchButton {
  cursor: pointer;
}

.itemDiv {
  z-index: 1;
}

.inventoryPageSub {
  margin-top: 100px;
  display: flex;
}

.content-section {
  margin-top: 50px;
}

.inventoryPageSub1 {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
}

.abilityIcon {
  /* position: relative; */
  display: flex;
  flex-direction: column;
  background: url(../../assets/blankAbility.png) no-repeat;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.ability {
  display: flex;
  position: relative;
  justify-items: center;
  align-items: center;
}

.abilityVerticalScroll {
  overflow-y: scroll;
  width: 100% !important;
  height: 365px !important;
}

.image-wrapper {
  position: relative;
  height: 110px;
}

.starsContainer {
  width: 100%;
  height: 25%;
  position: absolute;
  bottom: 8px;
  left: 0px;
  align-items: end;
  padding: 4px;
}

.star {
  height: 100%;
  background-image: none;
  background-color: transparent;
}

.star img {
  background-image: none;
  padding: 0px;
  z-index: 2;
  width: 100%;
  height: 100%;
}

.pictureGroup {
  position: relative;
  margin-left: 15%;
}

.modalCharacterImageInv {
  position: relative;
  z-index: 1;
  background-size: 100% 100%;
  margin-top: 30px;
  justify-items: center;
}

.smoke {
  position: absolute;
  top: 0px;
  left: 0px;
  translate: -10% 0%;
  opacity: .6;
  width: 130%;
}

.ability {
  position: relative;
  top: 40px;
  display: flex;
  justify-self: center;
  align-items: center;
  align-self: center;
}

.abilityValue p {
  font-family: "CarterOne";
  color: #c4cacc;
  text-transform: uppercase;
  z-index: 1;

  text-shadow: 0px 0px 10.15px #ff7a00;
}

.attributeValue {
  font-size: x-large;
  text-shadow: 0px 0px 10.15px #ff7a00;
}

.coverBg {
  background: url(../../assets/Characterbackground.png) no-repeat;
  background-repeat: round;
}

.charImg {
  position: relative;
  /* background: url(../../assets/Characterbackground.png) no-repeat; */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  justify-content: center;
  display: flex;
  flex: 0 0 auto;
}

.filterIcon {
  cursor: pointer;
}

.imagesMap {
  position: relative;
  height: 10%;
  width: 10;
}

.yours-custom-class {
  height: 10%;
  width: 10%;
}

.transparentBackground {
  background: url(../../assets/transparentBackground.png) no-repeat;
  background-size: cover;
  background-position: center;
  height: 500px;
  /* width: 600px; */
  opacity: 0.8;
  margin-top: -15px;
  box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.5);
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {
  display: block;
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

@media screen and (max-width: 1440px) {
  .inventoryPage {
    overflow-y: scroll;
  }
}

@media screen and (max-width: 425px) {
  .inventoryPage {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .RectangleHeaderText2 {
    font-size: medium;
  }
}

@media screen and (max-width:70em) {

  .sideItems {
    padding-top: 15% !important;
  }
}