.quest-card {
        position: relative;
        width: 100%;
        max-width: 100%;
        /* min-width: 250px; */
        height: 300px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        /* background-clip: padding-box; */
        z-index: 2 !important;
}

.fireBg {
        display: flex;
        background-image: url(../../../../assets/fireBorder.png);
        background-size: 130% 130% !important;
        margin: -4% -8% -2% -3%;
        padding: 4% 8% 1% 3%;
        background-position: center;
        background-repeat: no-repeat;
        /* position: absolute;
        top: 0px;
        left: 0px;
        flex: content;
        translate: -15% -20%;
        width: 150%;
        height: 140%;
        z-index: -1; */
}

.quest-bg-1 {
        background-image: url(../../../../assets/quests/card1.png);
}

.quest-bg-2 {
        background-image: url(../../../../assets/quests/card2.png);
}

.quest-bg-3 {
        background-image: url(../../../../assets/quests/card3.png);
        background-clip: padding-box;
}

.reward {
        background-image: url(../../../../assets/quests/flag.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position-x: center;
        width: 15%;
        min-width: 50px;
        height: 25%;
        margin: -2% 0 0 2%;
        padding-top: 1%;
}

/* 
.reward-content {
        width: min-content;
        margin: 11% 0 0 7%;
} */

.reward-value {
        color: white;
        font-size: 18px;
}

.reward-text {
        color: gray;
        font-size: 10px;
}

.card-content {
        margin-top: -40px;
}

.body-content {
        padding: 15% 0;
}

.btn-green {
        background: url(../../../../assets/MintButton2.png);
        background-repeat: round;
        border-radius: 10px;
        background-size: cover;
        font-family: "CarterOne";
        color: azure;
        text-transform: uppercase;
        width: fit-content;
        padding: 5px 15px;
}

.btn-green:hover {
        transition: background-color 0.3s;
        cursor: pointer;
}