.fightersPage {
  background: url(../../assets/loggedin.png) no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
  width: 100%;
  overflow: auto;
}

.RectangleHeader {
  z-index: 1;
}

.RectangleHeaderText {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  z-index: 1;
  letter-spacing: 0.02em;
  color: #c4cacc;
  text-shadow: 1.14509px 2.29018px 1.14509px rgb(0 0 0 / 46%);
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(0, 0, 0);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2e2e2e;
}

.itemDiv {
  display: flex;
}

.mintnft {
  cursor: pointer;
  width: 150px;
  height: 150px;
}

.selected {
  background-image: url(../../assets/selected.png);
}

.containerR {
  background: url(../../assets/Rectangle2.svg) no-repeat;
  background-size: cover;
  height: 600px;
  /* overflow: auto; */
  width: 100%;
  margin-top: -30px;
  position: relative;
}

.flag {
  position: absolute;
  top: 2%;
}

.selectcharacterback {
  position: absolute;
  width: 170%;
  height: 94%;
  max-width: 814px;
  bottom: -3.5%;
  right: -33%;
}

.max-h {
  max-height: 600px;
}

.image-collection {
  padding: 35px 10px 15px 10px;
  width: 100%;
  height: 100%;
}

.image-gallery-home {
  gap: 7px;
  height: 100%;
  overflow-y: scroll;
}

.p-top {
  padding-top: 150px;
}

.YourFightersHeader {
  /* display: flex; */
  flex-direction: column;
  flex-wrap: wrap;
  /* width: 50%; */
  height: 580px;
  align-content: start;
  justify-content: start;
  align-items: start;
}

.main-grid {
  display: grid;
  grid-template-columns: repeat(2,
      1fr);
  /* specify the number of columns here */
  gap: 10px;
  /* set the gap between grid items */
}

.questWraperClass {
  position: relative;
  max-width: 100%;
  overflow-y: auto;
}

.quest-list-item {
  min-width: 150px !important;
}

.modal-banner {
  background-image: url(../../assets/nftFloorPrice.png);
  background-repeat: no-repeat;
  background-size: contain;
  height: 177px;
  width: 124px;
  /* position: absolute; */
  /* margin-left: 4%;
  margin-top: -0.5%; */
}

.selectedSection {
  background: url(../../assets/selectionpart.png) no-repeat;
  background-size: 100% 100%;
  background-position: center;
  background-position-y: 29px;
  height: 100%;
  min-width: 680px;
}

.nocntentText {
  font-size: 32px;
  color: #c4cacc;
  position: relative;
  display: flex;
  z-index: 1;
  justify-content: center;
  top: 20px;
  align-items: center;
}

.expBar {
  background: url(../../assets/barBackground.png);
  background-size: 100% 100%;
  background-position: center;
  height: 20px;
  width: auto;
}

.levelLabel {
  background: url(../../assets//levelLabel.png);
  background-size: cover;
  display: flex;
  flex-direction: row;
  background-position: center;
  height: 200px;
  width: 100px;
}

/*
.nftDesc {
   display: flex;
  flex: 0 0 auto;
  height: 100%; 
}*/

.charExp {
  height: 80px;
  margin: 35px 0px 0px 0px !important;
}

.border-gray {
  border: 2px solid slategrey;
  border-radius: 15px;
}

.chPeWrap {
  margin-top: 50px !important;
  /* max-height: 60% !important; */
  height: fit-content;
  overflow-y: auto;
}

.characterIcon {
  translate: 0% 0%;
  min-width: 175px !important;
}

.characterIcon img {
  max-width: 100% !important;
  min-width: 175px !important;
}

.mytext {
  padding-bottom: 2px;
  margin-bottom: 0px !important;
}

.progressBarCh {
  height: 100% !important;
  padding-top: 3px !important;
  padding-left: 3px !important;
  padding-right: 3px !important;
}

.perks {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100% !important;
  display: flex;
  flex: content;
  flex: 0 0 auto;
  margin: 0px !important;
  padding-top: 5px;
  padding-bottom: 5px;
}

.perksText {
  margin: 0px;
}

.perksText p {
  margin: 0% 0% 1% 5%;
}

.modal .headerImage {
  background: url(../../assets/rectangleHeader.png) no-repeat;
}

.modalPopup {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 4;
  top: 0%;
  background-color: rgba(0, 0, 0, 0.38);
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.modal-content-m {
  max-width: 1080px;
  height: 100%;
  min-height: 500px;
  width: 80vw !important;
  background-image: url(../../assets/mintModalBackground.png);
  background-repeat: round;
  background-size: cover;
  margin-top: -35px;
  /* overflow: hidden; */
}

.mintNftHeader {
  /* display: flex;
  flex-direction: row;
  justify-self: start;
  justify-content: stretch; */
  z-index: 10;
}

.incDec {
  background: url(../../assets/IncDecButton.png) no-repeat;
  border: none;
  width: 38px;
  height: auto;
  font-size: x-large;
  font-family: "CarterOne";
  color: #aaa;
  cursor: pointer;
  margin: 0 20px;
}

.IncDec {
  /* padding-left: 4%; */
  font-family: "CarterOne";
  color: #aaa;
  font-size: x-large;
  /* margin-block: auto; */
}

.mint-button {
  height: 150px;
  width: auto;
  margin-top: 5px;
}

.mintButton {
  background: url(../../assets/MintButton2.png) round;
  background-size: cover;
  /* background-position: 31% 100%; */
  width: 17%;
  min-width: 200px;
  height: 50px;
  color: #ffffff;
  font-family: "CarterOne";
  font-size: larger;
  -webkit-text-stroke: 0.5px black;
  text-shadow: 0.723634px 0.815208px 0.723634px rgba(0, 0, 0, 0.38);
  cursor: pointer;
  border: none;
  /* margin-top: 14%; */
  /* margin-right: 9%; */
}

.mintButton:hover {
  text-shadow: drop-shadow(5px 5px 10px rgb(255, 255, 255));
}

.close {
  color: #aaa;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
}

/* Show the modal when it is clicked */
.modal-open {
  display: block;
}

.modal-close {
  display: null;
}

@media screen and (max-width: 425px) {
  ::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  /* .x-pad{
    padding: 0px 100px;
  } */
}

@media (max-width: 1080px) {
  .fightersPage {
    height: 175vh;
  }

  .image-gallery {
    width: auto;
  }

  .yourFighterSec {
    background-size: 100% 100%;
    margin-top: -20px;
  }

  .rectHeader1 {
    height: 34px;
  }
}

@media (max-height: 720px) {
  .fightersPage {
    height: 130vh;
  }
}