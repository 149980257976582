/* Background */
.page {
    background: url(../../assets/QuestPageBackground.png) no-repeat;
    background-size: cover;
    position: fixed;
    background-position: center;
    width: 100%;
    height: 100%;
    overflow: auto;
    font-family: "CarterOne";
}

.list-item {
    border: 2px solid darkgrey;
    background-color: black;
    border-radius: 20px;
}

.sidebar-option {
    /* border: 2px solid darkgrey;
    border-radius: 12px; */
}

.input-field {
    background: transparent;
    border-radius: 7px;
    padding: 10px;
}

.submit-btn {
    background: url(../../assets/MintButton2.png) no-repeat;
    background-position: center;
    background-size: contain;
    width: 100%;
}

.submit-btn button {
    background: transparent;
    border: none;
    -webkit-text-stroke: 0.5px black;
    font-size: 14px;
    text-shadow: 0.723634px 0.815208px 0.723634px rgba(0, 0, 0, 0.38);
    font-family: "CarterOne";
    padding: 15px;
    color: #ffffff;
    cursor: pointer;
    box-sizing: border-box;
}