.chest-modal-backdrop {
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: brightness(0.1);
  width: 100%;
  height: 100vh;
  font-family: "CarterOne";
  /* padding: 10%; */
}

/* .closeModel {
  color: white;
  display: flex;
  width: 90%;
  justify-content: end;
  cursor: pointer;
  margin-top: 50px;
} */

.top-section {
  margin-top: 30px;
}

.chest-modal-body {
  min-height: 500px;
  max-height: 500px;
  overflow-y: auto;
}

.chest-modal-container {
  width: 700px;
  /* max-width: 50%; */
  min-width: 350px;
  height: max-content;

  background-image: url(../../../assets/chestModal.png);
  background-repeat: round;
  background-size: cover;
}

.chest-modal-heading {
  color: whitesmoke;
  font-size: x-large;
  text-transform: uppercase;
  -webkit-text-stroke: 0.5px black;
  text-shadow: 2px 2px 4px #000000;
  /* margin-top: 2%; */
}

.section {
  margin: 20px 0;
}

.section-title {
  background: url(../../../assets/DropDownBack.png) no-repeat;
  background-position: center;
  background-size: 56% 100%;
  width: 100%;
  height: auto;
  color: #c3cacc;
}

.section-item {
  background: rgba(0, 0, 0, 0.521);
  background-size: cover;
  height: 170px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px;
  width: 160px;
  flex-wrap: wrap;
}

.section-item p {
  color: #c3cacc;
  font-size: large;
  margin: 0;
  padding: 0;
}

.section-item img {
  height: 40px;
  width: auto;
}

.collectButton {
  background: url(../../../assets/MintButton2.png) no-repeat;
  background-position: center;
  background-size: 100% 100%;
  border: none;
  font-family: "CarterOne";
  color: #ffffff;
  height: 50px;
  width: 200px;
  font-size: large;
  -webkit-text-stroke: 0.5px black;
  text-shadow: 0.723634px 0.815208px 0.723634px rgba(0, 0, 0, 0.38);
}

@media screen and (max-width: 425px) {}