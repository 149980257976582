.main-page {
  background: url(../../assets/LoginScreen.png) no-repeat;
  height: 100vh;
  width: 100%;
  background-position: center;
  background-size: cover;
  overflow: hidden;
}

.logo {
  z-index: 10;
}

.main-container {
  background: url(../../assets/LoginScreenMainRectangle2.png) no-repeat;
  background-size: cover;
  background-repeat: round;
  margin-top: -50px;
  overflow: hidden;
  width: 35%;
  min-width: fit-content;
}

.wallet-container {
  font-weight: 400;
  font-size: 20px;
}

.h-60 {
  height: 60%;
}