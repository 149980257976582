.item {
    border-radius: 15px;
    height: 100px;
    background-image: url(../../../assets/rect1.png);
    background-size: cover;
    background-repeat: no-repeat;
}

.flag {
    background-image: url(../../../assets/quests/flag.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    width: 15%;
    min-width: 50px;
    height: 25%;
    margin: -2% 0 0 2%;
    padding-top: 1%;
}

.flag-value {
    font-size: 20px;
    color: white;
}

.pill {
    border-radius: 20px;
    padding: 5px;
    background: rgba(46, 46, 46, 0.3)
}