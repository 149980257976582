@keyframes up {
    from {
        transform: scaleY(1);
    }

    to {
        transform: scaleY(-1);
    }
}

@keyframes down {
    from {
        transform: scaleY(-1);
    }

    to {
        transform: scaleY(1);
    }
}

.flip-up {
    transform: scaleY(-1);
    animation-duration: 0.5s;
    animation-name: up;
    animation-play-state: running;
}

.flip-down {
    transform: scaleY(1);
    animation-duration: 0.5s;
    animation-name: down;
    animation-play-state: running;
}