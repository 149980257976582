.image-wrapper-home {
    position: relative;
    cursor: pointer;
    height: 100%;
    min-height: 100px;
    max-height: 150px !important;
    width: 100%;
    max-width: 150px;
    min-width: 100px;
    background-size: cover;
    overflow: hidden;
}

.characterimg {
    width: 100%;
    max-height: 150px;
    height: 100%;
    cursor: pointer;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
}

.image-container {
    position: absolute;
    width: 85%;
    height: 82%;
    overflow: hidden;
    background-color: rgba(98, 98, 98, 0.1);
}

.char-image {
    z-index: 2;
    position: absolute;
    translate: 0 -5% !important;
    width: 400%;
    height: 400%;
}

.progressBarConH {
    z-index: 3;
    position: absolute;
    top: 0px;
    left: 0px;
    pointer-events: none;
    width: 90%;
}

.expIconH img {
    background-size: cover;
}

.progressBarH {
    background-image: url(../../assets/barBackground.png);
    background-size: contain;
    background-repeat: no-repeat;
    padding: 3px;
    height: 12px !important;
}

.progressBar {
    background-image: url(../../assets/barBackground.png);
    background-size: 100% 15px;
    padding: 3px;
    width: 100% !important;
    height: 12px !important;
    align-content: center;
}

.progress {
    background-color: transparent !important;
    height: 75% !important;
}

.progress-bar {
    background-color: #0FFC43 !important;
}