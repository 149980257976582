.background {
    width: 100vw;
    height: 100vh;
    background-color: transparent !important;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1000;
    pointer-events: none;
}

.show {
    margin: 0px auto 0px auto;
    align-self: self-start;
}

.dropdown-menu {
    background-color: grey !important;
}

.dropdown-menu a {
    --bs-dropdown-link-hover-bg: rgb(160, 158, 158);
    --bs-dropdown-link-active-bg: rgb(66, 65, 65);
}


.card {
    margin-top: 250px;
    padding: 20px 50px 20px 50px;
    min-height: 250px;
    pointer-events: auto;
}

.optionButton {
    padding: 0px !important;
    margin: 0px !important;
    background-color: cyan !important;
}

.dropdownButton {
    background: rgb(95, 116, 95) !important;
    border: none !important;
    padding: 15px !important;
    color: #ffffff !important;
    cursor: pointer !important;
    box-sizing: border-box !important;
}

.optionButton button {
    background: rgb(95, 116, 95) !important;
    border: none !important;
    padding: 15px !important;
    color: #ffffff !important;
    cursor: pointer !important;
    box-sizing: border-box !important;
}