/* Background */
.questPage {
  background: url(../../assets/QuestPageBackground.png) no-repeat;
  background-size: cover;
  position: fixed;
  background-position: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  font-family: "CarterOne";
}

/* Quest Frame */
.questFrameDiv {
  margin-top: 7%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-left: 120px;
}

.questCardHolder {
  margin-top: 4%;
}

.questFrame {
  background: url(../../assets/questFrame2.png) no-repeat;
  background-position: center;
  background-size: 100% 100%;
  width: 320px;
  height: 70px;
}

.questFrame p {
  font-family: "CarterOne";
  color: #ffffff;
  margin-top: 17px;
}

.chestImg {
  cursor: pointer;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .chestsDiv {
    flex-direction: column;
  }
}

/* Quest Info */
.questInfo {
  display: flex;
}

.questInfoAttribute {
  display: flex;
  align-items: center;
  padding-right: 35px;
}

.questInfoAttribute img {
  padding-right: 5px;
}

.questInfoAttribute p,
.questInfoXpCoin p {
  color: #ffffff;
  font-size: x-large;
}

.questBoxPic3 {
  margin-top: -28%;
  background: url(../../assets/fireEffect.png) no-repeat;
}

/* Slider Background */
.sliderBackgroundQuest {
  top: 0px;
  left: 0px;
  background: url(../../assets/SliderBackground.png);
  background-size: 100% 100%;
  background-position: center;
  display: flex;
  justify-content: center;
  flex-direction: row;
  height: 130px;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

/* Quest Button */
.questButton {
  background: url(../../assets/MintButton2.png) no-repeat;
  background-position: center;
  background-size: contain;
  margin: 20px auto;
}

.questButton button {
  background: transparent;
  border: none;
  -webkit-text-stroke: 0.5px black;
  font-size: 14px;
  text-shadow: 0.723634px 0.815208px 0.723634px rgba(0, 0, 0, 0.38);
  font-family: "CarterOne";
  padding: 15px;
  color: #ffffff;
  cursor: pointer;
  box-sizing: border-box;
  /* Add this line */
}

/* Responsive Styles */
@media (max-width: 768px) {

  /* Quest Frame */
  .questFrameDiv {
    flex-direction: column;
    margin-left: 0;
  }

  .questInfo {
    display: flex;
    margin-top: 30px;
  }

  .questFrame {
    width: 280px;
    margin-bottom: 20px;
  }

  .questFrame p {
    margin-bottom: 20px;
  }

  /* Slider Background */
  .sliderBackgroundQuest {
    /* height: 100px; */
    width: 100%;
  }
}

@media (max-width: 576px) {

  /* Quest Frame */
  .questFrameDiv {
    margin-top: 40px;
  }

  .questInfo {
    display: flex;
    margin-top: 60px;
  }

  .questFrame {
    width: 240px;
  }

  .questFrame p {
    margin-bottom: 15px;
  }
}

/* Large Screen Styles */
@media (min-width: 1880px) {
  .questBoxPic3 {
    display: flex;
    margin-left: 32%;
    flex-direction: row;
    margin-right: 30%;
    padding-top: 100px;
  }
}

/* Extra Large Screen Styles */
@media (min-width: 3840px) and (max-height: 2550px),
(min-width: 3730px) {
  .questBoxPic3 {
    padding-top: 550px;
    background-position-y: bottom;
  }
}


@keyframes notifyChestFill {
  0% {
    scale: 1;
    rotate: 0 0 1 0deg;
  }

  10% {
    scale: 1.15;
  }

  10% {
    rotate: 0 0 1 15deg;
  }

  20% {
    rotate: 0 0 1 -15deg;
  }

  30% {
    rotate: 0 0 1 15deg;
  }

  40% {
    rotate: 0 0 1 -15deg;
  }

  50% {
    rotate: 0 0 1 0deg;
    scale: 1.15;
  }

  51% {
    scale: 1;
  }

  69.9% {
    scale: 1;
    rotate: 0 0 1 0deg;
  }

  70% {
    scale: 1.15;
    rotate: 0 0 1 15deg;
  }

  75% {
    rotate: 0 0 1 -15deg;
  }

  80% {
    rotate: 0 0 1 15deg;
  }

  90% {
    rotate: 0 0 1 -15deg;
  }

  95% {
    rotate: 0 0 1 15deg;
  }

  100% {
    rotate: 0 0 1 0deg;
    scale: 1.15;
  }
}

.animateChest {
  animation: notifyChestFill 1.5s infinite;
}