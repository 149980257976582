.perkItem img {
    width: 100% !important;
    height: 100% !important;
}

.perkIconBorder {
    background-image: url(../../../../assets/framecharacter.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 95% 95% !important;
    position: relative;
}

.perkIconBorder img {
    height: 50% !important;
    width: 50% !important;
}

.perkItem {
    width: 75px !important;
    height: 75px !important;
}

.perkLevel {
    position: absolute;
    left: 0px;
    top: 0px;
    padding: 7px 10px 7px 7px;
    z-index: 2;
}