body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

* {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "CarterOne";
  src: local("CarterOne-Regular"),
    url("./assets/CarterOne-Regular.ttf") format("truetype");
}

.cursor-pointer {
  cursor: pointer;
}

.address {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  max-lines: 1;
  white-space: nowrap;
}